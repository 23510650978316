@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --background: rgb(252 252 252);
  --a1: rgba(0 0 0 / 0.05);
  --a2: rgba(0 0 0 / 0.1);
  --foreground: rgb(28 28 29);
  --shadow: 0px 6px 20px rgb(0 0 0 / 20%);
  --unit: 8px;
}

:root {
  color-scheme: dark;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body,
#root {
  width: 100%;
  height: 100vh;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.split {
  display: flex;
}

.gutter {
  background-color: rgb(24, 24, 24);
  cursor: ew-resize;
  position: relative;
}

.gutter-vertical{
  cursor: ns-resize;
}

.gutter.gutter-horizontal::after {
  content: "";
  display: flex;
  background: rgb(255, 255, 255);
  border-radius: 50px;
  position: absolute;
  width: 4px;
  height: 41px;
  top: calc(50% - 20px);
  margin-left: calc(50% - 2px);
  opacity: 0.5;
}

.gutter.gutter-horizontal:hover::after {
  opacity: 1;
}
.notes-list {
  color: rgb(218, 221, 225);
  overflow-y: auto;
}

.notes-list .minimize-publish-status {
  border-left: 3px solid transparent;
}
.notes-list .minimize-publish-status.published {
  border-left-color: #008000;
  background: none;
}
.notes-list .published {
  background-color: #00800070;
}

.notes-list .published.active, .notes-list li:hover.published {
  color: white;
  background-color: #00800040;
}
.notes-list .minimize-publish-status.published.active, .notes-list li:hover.minimize-publish-status {
  background-color: rgba(255, 255, 255, 0.05);
}

.notes-list .minimize-publish-status.published.active {
  color: rgb(53, 120, 229);
}
.notes-list .active, .notes-list li:hover {
  background-color: rgba(255, 255, 255, 0.05);
}

.notes-list .active > .note-index, .notes-list li:hover .note-index {
  display: block;
}
.notes-list li {
  cursor: pointer !important;
}
.notes-list .active {
  color: rgb(53, 120, 229);
}

.markdown-body.hacknote {
  font-family: 'JetBrains Mono', monospace !important;
  font-size: 13px;
  color-scheme: light;
}

.markdown-body.hacknote mark {
  white-space: pre-wrap;
}

.markdown-body.hacknote pre {
  background: #faebd763;
}
.markdown-body.hacknote .alert {
  padding: 15px;
  margin-bottom: 20px;
  border: 1px solid transparent;
  border-radius: 4px;
}
.markdown-body.hacknote .alert *:last-child {
  margin-bottom: 0px;
}
.markdown-body.hacknote .alert.alert-success {
  color: #3c763d;
  background-color: #dff0d8;
  border-color: #d6e9c6;
}
.markdown-body.hacknote .alert.alert-info {
  color: #31708f;
  background-color: #d9edf7;
  border-color: #bce8f1;
}
.markdown-body.hacknote .alert.alert-warning {
  color: #8a6d3b;
  background-color: #fcf8e3;
  border-color: #faebcc;
}
.markdown-body.hacknote .alert.alert-danger {
  color: #a94442;
  background-color: #f2dede;
  border-color: #ebccd1;
}


.tiny-md-preview > .markdown-body * {
  font-size: 12px !important;
}

.tiny-md-preview > .markdown-body pre[class*="language-"] {
  display: none;
}

ol {
  list-style-type: decimal;
}

ul {
  list-style-type: disc;
}

.justify-center .bp4-tab-list{
  max-width: 720px;
  margin: 20px auto 0;
}

.pdf-viewer {
  max-width: 720px;
  margin: 20px auto 0;
}

.react-pdf__Document {
  color: #ddd;
}

.react-pdf__message {
  padding: 120px;
  text-align: center;
}



.yRemoteSelection {
  background-color: rgb(250, 129, 0, 0.5);
}
.monaco-remote-cursor {
  position: absolute;
  pointer-events: none;
  z-index: 4000;
  width: 2px;
}

.monaco-remote-cursor:before {
  content: "";
  width: 6px;
  height: 5px;
  display: block;
  margin-left: -2px;
  margin-top: 0;
  z-index: 4000;
  background: inherit;
}

.monaco-remote-cursor-tooltip {
  position: absolute;
  white-space: nowrap;
  color: #FFFFFF;
  opacity: 1.0;
  border-radius: 4px;
  font-size: 12px;
  padding: 2px;
  font-family: sans-serif;
  z-index: 4000;

  transition: opacity 0.5s ease-out;
  -webkit-transition: opacity 0.5s ease-out;
  -moz-transition: opacity 0.5s ease-out;
  -ms-transition: opacity 0.5s ease-out;
  -o-transition: opacity 0.5s ease-out;
}

.chrome-picker input {
  background-color: white;
}

.bp4-dark .bp4-panel-stack-view {
  background-color: transparent !important;
}

.monaco-editor .suggest-preview-text .view-line {
  right: 18px;
  left: 0;
  width: auto !important;
  word-break: break-word;
}