:root {
  --highlight-background: rgb(0 0 0 / 0);
  --highlight-width: 0;
}
.markdown-body code details[open] > summary {
  position: absolute;
  width: 0;
  opacity: 0;
}
.markdown-body code details {
  position: relative;
  margin-bottom: -16px;
  background: #ddf4ff50;
}
.markdown-body code details[open] {
  display: inline;
  width: 100%;
}
.markdown-body code summary {
  position: relative;
  background: #ddf4ff;
  color: #444;
  height: 16px;
}
.markdown-body code summary::marker {
  --appearance: none;
}

.line-folded {
  height: 0!important;
}
.line-hide-content::before {
  content: ''!important;
}

.line-numbers span.line-numbers-rows {
  margin-top: -1px;
}

.line-numbers span.line-numbers-rows > span {
  position: relative;
}

.line-numbers span.line-numbers-rows > span::after {
  content: " ";
  background: var(--highlight-background);
  width: var(--highlight-width);
  position: absolute;
  top: 0;
}
