.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.bp4-dark .bp4-menu {
  background: #2d2d2d!important;
}

.progress-btn:hover {
	transition: none;
}
.progress-btn {
	position: relative;
	transition: all 0.4s ease;
}
.progress-btn:not(.active) { cursor: pointer; }
.progress-btn .btn { text-align: center; z-index: 10; opacity: 1; }
.progress-btn .progress { width: 0%; z-index: 5; opacity: 0; transition: all 0.3s ease; }
.progress-btn.active .progress { opacity: 1; animation: progress-anim 10s ease 0s; }
.progress-btn.active .progress { animation: progress-indefinite-anim 4s infinite linear 0s; }
.progress-btn .progress { position: absolute; left: 0; top: 0; width: 100%; height: 100%; background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='50' height='100' viewBox='0 0 5 10'%3E%3Crect width='110%25' x='-5%25' y='-5%25' height='110%25' fill='transparent'/%3E%3Cline x1='-2' y1='1' x2='7' y2='10' stroke='%23aaa' stroke-width='2'/%3E%3Cline x1='-2' y1='6' x2='7' y2='15' stroke='%23aaa' stroke-width='2'/%3E%3Cline x1='-2' y1='-4' x2='7' y2='5' stroke='%23aaa' stroke-width='2'/%3E%3C/svg%3E"); }
@keyframes progress-anim { 0% { width: 0%; } 5% { width: 0%; } 10% { width: 15%; } 30% { width: 40%; } 50% { width: 55%; } 80% { width: 100%; } 95% { width: 100%; } 100% { width: 0%; } }
@keyframes progress-indefinite-anim { 0% { background-position: 0% 0%; } 100% { background-position: 100% 0%; } }

.dashboard-list-pagination li {
  list-style: none;
}
.dashboard-list-pagination .selected a {
  color: #fff;
}
.dashboard-list-pagination a {
  display: flex; 
  color: #999; 
  font-size: .8rem;
  justify-content: center; 
  align-items: center; 
  width: 2rem; 
  height: 2rem; 
  border-radius: 0.175rem; 
}